@use "sass:math";

.wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

section {
  padding: 3rem 2rem;
  overflow-x: hidden;

  .text-panel {
    overflow-y: auto;
    padding: 1rem;
    background-color: #FFF;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);

    .columns {
      padding-top: 0;
    }
  }

  @media (min-width: 960px) {
    .text-panel {
      position: absolute;
      width: 50vw;
      max-width: 380px;
      max-height: calc(100% - 2rem);

      &.left {
        left: 1rem;
        bottom: 1rem;
      }
  
      &.right {
        right: 1rem;
        bottom: 1rem;
      }

      &.middle {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 860px;
        max-width: calc(100% - 2rem);
      }
    }
  }

  &.bg-dark {
    background: rgb(23,41,50);
    background: -moz-linear-gradient(top, rgba(23,41,50,1) 0%, rgba(8,19,25,1) 100%);
    background: -webkit-linear-gradient(top, rgba(23,41,50,1) 0%,rgba(8,19,25,1) 100%);
    background: linear-gradient(to bottom, rgba(23,41,50,1) 0%,rgba(8,19,25,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#172932', endColorstr='#081319',GradientType=0);

    color: #FFF;
  }

  &.bg-light {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top, rgb(255,255,255) 0%, rgba(250,250,250,1) 100%);
    background: -webkit-linear-gradient(top, rgb(255,255,255) 0%,rgba(250,250,250,1) 100%);
    background: linear-gradient(to bottom, rgb(255,255,255) 0%,rgba(250,250,250,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fafafa',GradientType=0);
  }

  &.bg-grey {
    background: #EEE;
  }

  &.bg-image {
    background-size: cover;
    background-position: center center;
  }

  &.full-imagery {
    position: relative;
    padding: 0;
    overflow-y: hidden;
    z-index: 1;

    & > img,
    & > video {
      width: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: 960px) {
      height: calc(100vh - #{$header-height});

      & > img,
      & > video {
        height: 100%;
      }
    }
  }

  &.image-toggle {
    position: relative;
    padding: 0;

    h1 {
      position: absolute;
      top: 2rem + $header-height;
      left: 50%;
      transform: translateX(-50%);
      color: #FFF;
    }

    > img {
      width: 100vw;
      object-fit: cover;
    }

    @media screen and (min-width: 960px) {
      overflow-y: hidden;
      height: calc(100vh - #{$header-height});

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .toggles {

      li {
        cursor: pointer;

        h3 {
          position: relative;
          padding-left: 1rem;
          user-select: none;
        }

        h3:before {
          content: $fa-var-angle-right;
          position: absolute;
          left: 0rem;
          top: 0.1rem;
          font-family: "FontAwesome";
          font-size: 1.2rem;
          margin-right: 0.5em;
        }

        .toggle-content {
          max-height: 0;
          overflow: hidden;
          transition: all 300ms ease;
          //font-size: 0.9rem;

          p:last-child {
            margin-bottom: 2rem;
          }
        }

        &.active {
          h3:before {
            transform: rotate(90deg);
          }

          .toggle-content {
            max-height: 50vh;
          }
        }
      }
    }
  }
}

// COLUMNS
$column-count: 12;
$column-width: math.div(100%, $column-count);

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 0 -1rem;
  padding: 2rem 0 0;

  &.column-count-1 {
    padding-top: 1rem;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  .column {
    margin-bottom: $space * 2;
    padding: 0 1rem;
    flex-shrink: 0;

    // &:last-child {
    //   margin-bottom: 1rem;
    // }
  }
}

@mixin columns($size: "") {
  .visible#{$size} { display: block; }
  .invisible#{$size} { display: none; }

  @for $i from 1 through $column-count {
    .column-width#{$size}-#{$i} {
      position: relative;
      width: $column-width * $i;
      padding: 0 math.div($space, 2);
    }
  }
}

@include columns("-s");
@media screen and (min-width: $breakpoint-m)  { @include columns("-m");  }
@media screen and (min-width: $breakpoint-l)  { @include columns("-l");  }
@media screen and (min-width: $breakpoint-xl) { @include columns("-xl"); }