// RESETS
html {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  text-size-adjust: none;
}

iframe {
  max-width: 100%;
  border: none;
}

figure {
  margin: 0;
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  outline: none;
  border: none;
}

video {
  display: block;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
}

header, footer, section, div, span, ul, ol, li, h1, h2, h3, h4, h5 {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

.svg-container {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.svg-container svg {
  position: absolute;
  top: 0;
  left: 0;

}