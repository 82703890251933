@use "sass:math";

@mixin visible-site-title {
  #site-title {
    display: inline-block;
    background-color: #FFF;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);

    span {
      opacity: 1;
    }

    &:before {
      color: $color-blue-dark;
      opacity: 1;
    }

    &:after {
      transform: translateY(0rem);
    }
  }
}

#site-navigation {

  #site-title {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: math.div($header-height, 4) 2.5rem;
    font-size: math.div($header-height, 2);
    line-height: math.div($header-height, 2);
    vertical-align: middle;
    background-color: transparent;
    z-index: 2500;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    transition: all 200ms ease;
    pointer-events: none;

    span {
      opacity: 0;
      pointer-events: all;
    }

    // ICON
    &:before {
      content: $fa-var-bars;
      position: absolute;
      left: 0.75rem;
      top: 0.75rem;
      font-family: "FontAwesome";
      font-size: 1.25rem;
      color: $color-blue-dark;
      cursor: pointer;
      pointer-events: all;
      transition: transform 100ms ease;
    }

    // BAR
    &:after {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: $header-height;
      background-color: #FFF; 
      transform: translateY(-2rem);
    }
  }

  > ul {
    position: fixed;
    top: 2rem;
    left: 0.35rem;
    max-width: calc(100% - 0.7rem);
    padding: 0.25rem 0.75rem;
    background-color: #FFF;
    z-index: 2400;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    //transform: translateY(-100%);
    transition: all 150ms ease;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.1);

    ul {
      margin: 0 0.5rem 0 0.75rem;
    }
  }

  li a {
    display: block;
    padding: 0.5rem 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.current {
      font-weight: 700;
    }
  }

  // NAV IS TOGGLED
  &.nav-toggled {

    #site-title {
      //background-color: #FFF;

      // span {
      //   opacity: 1;
      // }

      &:before {
        color: $color-blue-dark;
        opacity: 1;
        transform: rotate(90deg);
      }
    }

    > ul {
      top: $header-height + 0.35rem;
      //transform: translateY(0);
      opacity: 1;
      visibility: visible;
      transition: all 50ms ease;
    }
  }

  // PAGE IS SCROLLED
  @media screen and (max-width: $breakpoint-l) {
    @include visible-site-title
  }
  &.visible,
  &.scrolled {
    @include visible-site-title
  }

}
