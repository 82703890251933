$font-family: "Work Sans", sans-serif;

//$color-blue-light: #172932;
$color-blue-light: #233f4d;
$color-blue-dark:  #081319;
$color-yellow:     #b7b709;
$color-red:        #ba0848;
$color-cyan:       #34f7fe;
$color-blue:       #150173;

$color-text: #ffffff;


// BREAKPOINTS
$breakpoint-m:  600px;
$breakpoint-l:  800px;
$breakpoint-xl: 1000px;

$space: 1.5rem;

//
$header-height: 3rem;
