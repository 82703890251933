body {
  font-family: $font-family;
  font-weight: 300;
  font-size: 15px;

  // @media (min-width: 600px) {
  //   font-size: 16px;
  // }
}

h1, h2, h3, h4, h5, b, strong {
  font-weight: 500;
}

h1, h2, h3, h4 {
  margin: 0;
  text-transform: uppercase;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

h1 {
  margin: 0 0 1em 0;
  font-size: 1.75rem;

  @media (min-width: 600px) {
    font-size: 2rem;
  }
}

h2 {
  margin: 0 0 1.5em 0;
}

h3 {
  margin: 0 0 0.5em 0;
}

h4 {
  margin: 0 0 0.25em 0;
  font-size: 1em;
  font-weight: 300;
  text-transform: uppercase;
}

h5 {
  margin: 1em 0 0.25em 0;
  font-weight: 300;
  text-transform: uppercase;
}

p {
  margin: 0 0 1.5em 0;
  line-height: 1.5em;

  &.introduction {
    font-size: 1.1em;
  }
  &.actions {
    margin-top: 3.5em;
    text-align: right;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

li {
  line-height: 1.5em;
}

::selection {
  background: $color-yellow;
  color: #FFF;
}

.bg-light {
  ::selection {
    background: $color-yellow;
    color: #000;
  }
}


.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.caption {
  margin: 1rem 0;
  text-align: center;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.25em;
  }
  p {
    font-style: italic;
  }
}
