@import "_resets";
@import "_variables";
@import "_fonts";
@import "_layout";
@import "_typography";
@import "_links";

@import "_header";
@import "_navigation";
@import "_ui";
@import "_viz";



g.active, g.inactive {
  cursor: pointer;
}

g.inactive {
  opacity: 0.3;
}

g.hidden {
  opacity: 0;
}


#map {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  margin-bottom: 2rem;

  & > div {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
}

.mapboxgl-marker {
  background-color: $color-blue-dark;
  padding: 0.2em 0.4em;
  white-space: nowrap;

  h3 {
    font-size: 1rem;
  }

  th {
    text-align: left;
    text-transform: uppercase;
    padding-right: 0.5em;
    padding-bottom: 0.5em
  }
  td {
    vertical-align: top;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 4px;
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $color-blue-dark;
  }
}