#page-header {
  padding-top: 3rem;
  background-color: $color-blue-dark;
  color: #FFF;

  .text {
    text-align: center;
    color: #FFF;
    padding: 5rem 0;
  }

  #site-navigation.hidden + & {
    padding-top: 0;
  }
}

#page-header.bg-image-header {
  position: relative;

  .image {
    height: 20vw;
    min-height: 300px;
    background-size: cover;
    background-position: center center;
    opacity: 0.5;
    }

  @media screen and (max-width: $breakpoint-l) {
    padding-top: 3rem;

    .image {
      // margin-bottom: -2px;
      opacity: 1;
    }

    .text {
      padding: 2rem 0;
      background-color: #FFF;
      color: $color-blue-dark;

      .wrapper {
        width: 90%;
        max-width: 640px;
        margin: 0 auto;
      }
    }
  }

  @media screen and (min-width: $breakpoint-l) {
    margin-bottom: -2px;

    .image.large {
      min-height: 540px;
    }

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      max-width: 640px;
      margin: 0;
      transform: translate(-50%, -50%);
    }
  }
}

#home #page-header {
  .image {
    height: 34vw;
    opacity: 1;
    background-position: center top;
   }

   .text {
    color: $color-blue-dark;
    top: 25%;
   }
   @media screen and (max-width: $breakpoint-l) {
    // .text {
    //   color: #FFF;
    // }
    h1 {
       display: none;
    }
   }
   @media screen and (min-width: $breakpoint-l) {
    .image {
      min-height: 500px;
    }
  }
}

