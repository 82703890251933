a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.button {
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1em;
  background-color: #FFF;
  color: $color-blue-dark;
  padding: 0.5em 0.75em;

  &:after {
    font-family: "FontAwesome";
    content: $fa-var-angle-right;
    margin-left: 0.5em;
  }

  .bg-grey &,
  .bg-light & {
    background-color: $color-blue-dark;
    color: #FFF;
  }
}

.clickable {
  cursor: pointer;
}

h3 span {
  margin-right: 0.5rem;
  padding: 0.2em 0.5em;
  background: #EEE;
  border-radius: 1em;
  cursor: pointer;
  user-select: none;

  &.active {
    background: $color-blue-dark;
    color: #FFF;
  }
}