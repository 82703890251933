svg {
  display: block;

  text {
    user-select: none;
  }
}

clipPath {
  pointer-events: none;
}


#water-quality-visualization {
  padding: 0;

  .wrapper {
    position: relative;
    padding: 2rem 0;
  }
}

.chart {
  position: relative;
}



#charts {
  //position: relative;
  margin-bottom: 2rem;

  .recharts-responsive-container {
    position: relative;
    z-index: 1000;
  }


  .chart-header {
    position: absolute;
    top: 10px;
    width: 20px;
    z-index: 1500;
    border-right: 1px solid #FFF;
  
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      box-sizing: border-box;
      border-top: 1px solid #FFF;
      border-bottom: 1px solid #FFF;
    }
  }

  .chart-footer {
    position: absolute;
    top: 10px;
    width: 20px;
    border-left: 1px solid #FFF;
  
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 20px;
    //   height: 100%;
    // }
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 2px;
    //   height: 100%;
    //   box-sizing: border-box;
    //   border-top: 1px solid #FFF;
    //   border-bottom: 1px solid #FFF;
    // }
  }

  .charts-side {
    position: absolute;
    top: 0;

    .chart-part {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 20px;
        height: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 2px;
        height: 100%;
        box-sizing: border-box;
        border-top: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
      }
    }
  }

  #charts-headers {
    right: 100%;


    .chart-part {
      width: 20px;
      border-right: 1px solid #FFF;

      &:before {
        left: 100%;
        box-shadow: inset 2rem 0 1.5rem -1.5rem rgba(0,0,0,0.2);
      }
      &:after {
        right: 0;
      }
    }
  }
  #charts-axes {
    left: 100%;

    .chart-part {
      width: 30px;
      border-left: 1px solid #FFF;

      &:before {
        right: 100%;
        box-shadow: inset -2rem 0 1.5rem -1.5rem rgba(0,0,0,0.2);
      }
      &:after {
        left: 0;
      }
    }
  }
  #charts-viewport {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    //direction: rtl;

    &::-webkit-scrollbar { display: none; }

    #charts-charts {
      position: relative;
      min-width: 800px;
      height: 100%;
    }
  }

}

.chart-scroll-part {
  background-color: rgba(0,0,0,0.075);
}

.chart-part {
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.chart-time-part {
  height: 40px;
}


#controls {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  z-index: 200;
}

.control {
  input {
    display: none;
  }
  label {
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
  }
}

#control-source {
  position: relative;
  padding-right: 2rem;

  label {
    margin-right: 1rem;
  }

  label:hover,
  input:checked + label {
    text-decoration: underline;
  }
}

#control-time-preset {

  label {
    margin-left: 0.5rem;
  }

  label:hover,
  input:checked + label {
    text-decoration: underline;
  }
}


.axis {
  path,
  line {
    stroke: #FFF;
  }
  text {
    fill: #FFF;
    letter-spacing: 0.1em;
    font-weight: 300;
    font-size: 9px;
  }
}


.minmax {
  opacity: 0.8;
}

.average {
  path {
    fill: none;
    stroke-width: 1px;
  }
  .average-bg {
    stroke: white;
  }
  .average-dotted {
    stroke: $color-blue-light;
    stroke-dasharray: 4,2;
  }
}

stop {
  &.white { stop-color: #FFF; }
  &.yellow { stop-color: $color-yellow; }
  &.red { stop-color: $color-red; }
  &.cyan { stop-color: $color-cyan; }
  &.blue { stop-color: $color-blue; }
}


#charts-x-axis {
  & > g {
    transform: translateY(40px);
  }

  .axis-months {
    text {
      text-anchor: start;
      transform: translateX(3%);
    }
  }

  text {
    text-transform: lowercase;
  }
}

.chart-header {
  cursor: pointer;

  &:hover header {
      color: $color-yellow;
      transition: all 200ms ease;
    }

  header {
    position: absolute;
    bottom: -1px;
    left: 1rem;
    //transform: rotate(180deg);
    //writing-mode: vertical-rl;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    pointer-events: none;
    user-select: none;

    .suffix {
      font-size: 0.9em;
      text-transform: none;
    }
  }

  .chart-header {
    position: absolute;
    top: 0;
    right: 100%;

    line {
      stroke-width: 1px;
    }
  }
}


.chart-axis {

  .chart-y-axis {
    position: absolute;
    top: -5px;
    left: 0;

    .domain {
      stroke: none;
    }

    .tick:first-of-type {
      line {
        stroke: none;
      }
    }

    & > g {
      transform: translateY(5px);
    }

    // text {
    //   transform: rotate(45deg) translate(0px, -5px);
    // }
  }
}


button.time-scroll {
  position: absolute;
  top: 8px;
  background: none;
  border: none;
  color: #FFF;
  cursor: pointer;
  font-size: 1.25rem;

  &:before {
    font-family: "FontAwesome";
  }

  &.back {
    right: 0.125rem;
    &:before { content: $fa-var-angle-left; }
  }
  &.forward {
    left: 0.125rem;
    &:before { content: $fa-var-angle-right; }
  }

  &:hover {
    transform: scale(1.2);
  }

  &.disabled {
    opacity: 0.5;
    cursor: no-drop;

    &:hover {
      transform: scale(1.0);
    }
  }
}




// .hightlight {
//   position: absolute;
//   top: 0;
//   height: 100%;
//   background: #FFF;
//   mix-blend-mode: overlay;
//   opacity: 0.3;
//   z-index: 100;
// }


.info-box {
  position: absolute;
  min-width: 14rem;
  max-width: 90vw;
  padding: 0.5rem;
  padding-right: 1.5rem;
  background-color: #FFF;
  color: $color-blue-dark;
  box-shadow: 0 0 0.5em 0 rgba(0,0,0,0.1);
  z-index: 1300;
  font-size: 0.9em;
  user-select: none;

  .arrow {
    content: "";
    position: absolute;
    top: calc(50% - 0.25rem);
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    background-color: #FFF;
  }

  &.left {
    transform: translate(-100%, -50%);

    .arrow {
      left: calc(100% - 0.25rem);
    }
  }

  &.right {
    transform: translateY(-50%);

    .arrow {
      right: calc(100% - 0.25rem);
    }
  }



  .close {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    line-height: 1em;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover {
      color: $color-red;
    }
  }

  p {
    max-width: 20em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-red;
  }

  .more {
    position: relative;
    padding-left: 0.75em;
    text-transform: lowercase;

    &:before {
      position: absolute;
      content: $fa-var-angle-right;
      font-family: "FontAwesome";
      left: 0;
      top: 2px;
    }
  }

  .value {
    position: relative;
    font-size: 1em;
    display: block;
    padding: 0 0.25em 0 1em;
    margin: 0 0.25em 0.25em 0;
    background-color: $color-yellow;
    color: $color-blue-dark;
    border-radius: 3px;

    .type {
      position: absolute;
      top: 50%;
      left: 0.25em;
      text-transform: uppercase;
      font-size: 0.5em;
      line-height: 1em;
      writing-mode: vertical-rl;
      //transform: rotate(90deg);
      transform: translateY(-50%);
    }

    .suffix {
      font-size: 0.8em;
    }

    &:after {
      content: "";
      position: absolute;
      right: 100%;
      top: 0.35em;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.2em 0.25em 0.2em 0;
      border-color: transparent $color-yellow transparent transparent;
    }
  }
}

.values {
  display: inline-block;
  position: relative;
  margin: 0.5em 0;
  padding-right: 0.5em;

  .value {
    position: absolute;
    left: 100%;
    transform: translateY(-50%);
    white-space: nowrap;

    &.max {
      top: 0%;
    }
    &.avg {
      top: 50%;
    }
    &.min {
      top: 100%;
    }
  }
  
  

}

.maxavgmin {
  width: 40px;
  height: 40px;

  line,
  path {
    fill: none;
    stroke: $color-blue-dark;
    stroke-width: 1;
    stroke-miterlimit: 10;
    stroke-linecap: round;
  }
}



.hover-area {
  fill: rgba(0,0,0,0.1)
}

foreignObject {
  text-align: right;
  overflow: visible;
}

.tooltip {
  display: inline-block;
  background-color: #FFF;
  color: #000;
  padding: 0 0.25rem;
  line-height: 22px;
  box-shadow: 0 0 0.5em 0 rgba(0,0,0,0.5);
  z-index: 2000;
  white-space: nowrap;
  user-select: none;

  &.date-tooltip {
    position: relative;
    transform: translate(-50%, -14px);
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: calc(50% - 5px);
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #FFF;
    }
  }

  &.data-tooltip {
    position: relative;
    user-select: none;

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 5px);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }

    &.left {
      transform: translateX(-100%);

      &:after {
        left: calc(100% - 1px);
        border-left: 5px solid #FFF;
      }
    }

    &.right {
      &:after {
        right: calc(100% - 1px);
        border-right: 5px solid #FFF;
      }
    }
  }
}

.recharts-surface {
  overflow: visible;
  pointer-events: none;
}

.recharts-brush,
.recharts-reference-area {
  pointer-events: all;
}

.recharts-reference-area {
  cursor: pointer;

  path {
    fill: #000; 
    mix-blend-mode: multiply;
    opacity: 0.4;

    &:hover {
      opacity: 0.8;
      transition: all 200ms ease;
    }
  }

  .warning {
    cursor: pointer;
    pointer-events: none;
  
    circle {
      fill: #FFF;
    }
  
    text {
      fill: $color-blue-dark;
      text-anchor: middle;
      font-size: 0.9rem; 
      font-weight: 600;
    }
  }

  &:hover .warning {
    circle {
      fill: $color-yellow;
      transition: all 200ms ease;
    }
  }

}


.recharts-tooltip-wrapper {
  top: auto !important;
  bottom: 20px;
}

.recharts-xAxis {
  font-size: 0.9em;
}


.recharts-brush {
  &:hover {
    .recharts-brush-slide {
      fill-opacity: 0.4;
      transition: fill-opacity 200ms ease;
    }
  }
}

.current-value {
  position: absolute;
  right: 30px;
  transform: translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: 100%;
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #FFF;
  }
}