// Font Awesome
@import "fa/variables";
@import "fa/path";
@import "fa/core";
@import "fa/icons";


// Work Sans
@font-face {
  font-family: "Work Sans";
  font-weight: 300;
  font-style: normal;
  src:  url(../fonts/work-sans/WorkSans-Light.woff2) format('woff2'),
        url(../fonts/work-sans/WorkSans-Light.woff) format('woff'),
        url(../fonts/work-sans/WorkSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: normal;
  src:  url(../fonts/work-sans/WorkSans-Regular.woff2) format('woff2'),
        url(../fonts/work-sans/WorkSans-Regular.woff) format('woff'),
        url(../fonts/work-sans/WorkSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: normal;
  src:  url(../fonts/work-sans/WorkSans-Medium.woff2) format('woff2'),
        url(../fonts/work-sans/WorkSans-Medium.woff) format('woff'),
        url(../fonts/work-sans/WorkSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: normal;
  src:  url(../fonts/work-sans/WorkSans-SemiBold.woff2) format('woff2'),
        url(../fonts/work-sans/WorkSans-SemiBold.woff) format('woff'),
        url(../fonts/work-sans/WorkSans-SemiBold.ttf) format('truetype');
}